<template>
  <app-modal title="Create a new proposal" @submit="onSubmit">
    <v-btn-toggle
      v-model="mode"
      class="mb-4 !grid w-full grid-cols-2"
      color="primary"
      mandatory
      variant="outlined"
    >
      <v-btn data-cy-from-hubspot-deal value="from_hubspot_deal">
        From Hubspot Deal
      </v-btn>
      <v-btn data-cy-from-scratch value="from_scratch"> From scratch </v-btn>
    </v-btn-toggle>

    <hubspot-deal-autocomplete
      v-if="mode === 'from_hubspot_deal'"
      v-model="form.hubspot_deal_id"
      required
      @update:deal="onHubspotDealUpdate"
    />

    <app-form-input-text
      v-model="form.title"
      data-cy-proposal-title
      label="Title"
      :readonly="!allowTitleAndCustomerEdition"
      required
    />

    <proposal-customer-autocomplete
      v-model="form.customer_company_name"
      data-cy-proposal-customer
      :readonly="!allowTitleAndCustomerEdition"
      required
    />

    <proposal-language-select v-model="form.language" required />
  </app-modal>
</template>

<script lang="ts" setup>
import type { HubspotDeal } from "@pollen/core/model/hubspot.model";

import ProposalLanguageSelect from "~/business-areas/proposal/components/proposal-language-select.vue";

const mode = ref<"from_hubspot_deal" | "from_scratch">("from_hubspot_deal");

const { me } = useCurrentUser();

const form = reactive<DatabaseInsertRequest<"proposals">>({
  title: undefined as unknown as string,
  customer_company_name: undefined as unknown as string,
  owner_id: me.value!.id,
  hubspot_deal_id: undefined,
  language: "en",
});

async function onHubspotDealUpdate(deal?: HubspotDeal) {
  if (!deal) {
    form.title = "";
    form.customer_company_name = "";

    return;
  }

  form.title = deal.properties.dealname;

  try {
    const companies = await $fetch(`/api/hubspot/deals/${deal.id}/companies`);
    form.customer_company_name = companies[0]?.properties.name ?? "";
  } catch {
    // Silent error
  }
}

const allowTitleAndCustomerEdition = computed(() => {
  if (mode.value === "from_scratch") return true;

  return form.hubspot_deal_id !== undefined;
});

function onSubmit() {
  return useModal("proposalCreationModal").close({
    confirmed: true,
    payload: form,
  });
}
</script>
